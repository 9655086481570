import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/homePage';
import { CardInstructions } from './pages/cardInstructions';
import { TimerAppPage } from './pages/timerApp/timerAppPage';
import { OrganizationListPage } from './pages/orgs/organizationListPage';
import { OrganizationPage } from './pages/orgs/organizationPage';

import { TeamPage } from './pages/team/teamPage';
import './app.css';

import { AdminPage } from './pages/admin/adminPage';
import { AddParticipantPage } from './pages/team/addParticipantPage';
import { UpdateSponsorPage } from './pages/orgSeason/updateSponsorPage';
import { ReportCardGpaPage } from './pages/scholastics/reportCardGpaPage';

import { OrganizationAdminPage } from './pages/orgs/organizationAdminPage';
import { OrderFormPage } from './pages/payment/orderFormPage';
import { CheckoutStatusPage } from './pages/payment/checkoutStatusPage';
import { UpdateUserPage } from './pages/admin/updateUserPage';
import { Footer } from './shared/footer';
import { Header } from './shared/header';
import { Box, Grid } from '@mui/material';
import { MembersPage } from './pages/member/membersPage';
import { LeadersPage } from './pages/leader/leadersPage';
import { OrgSeasonPage } from './pages/orgSeason/orgSeasonPage';
import { OrgSeasonAdminPage } from './pages/orgSeason/orgSeasonAdminPage';
import { ProfileCapturePage } from './pages/orgSeason/profileCapturePage';
import { CardDataFormPage } from './pages/member/cardDataFormPage';


export function App() {
  return (
    <>
      <Header />
      <Box className='mainContent'>
        <Grid container spacing={2}>
          <Grid item xs={1} />

          <Grid item xs={10} >
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/report-card/gpa-sheet' element={<ReportCardGpaPage />} />
                <Route path='/trading-card/instructions' element={<CardInstructions />} />
                <Route path='/timer' element={<TimerAppPage />} />
                <Route path='/order' element={<OrderFormPage />} />
                <Route path='/checkout-status' element={<CheckoutStatusPage />} />
                <Route path='/orgs' element={<OrganizationListPage />} />
                <Route path='/orgs/:orgId' element={<OrganizationPage />} />
                <Route path='/orgs/:orgId/admin' element={<OrganizationAdminPage />} />
                <Route path='/orgseason/:orgSeasonId' element={<OrgSeasonPage />} />
                <Route path='/orgseason/:orgSeasonId/team/:teamId' element={<TeamPage />} />
                <Route path='/orgseason/:orgSeasonId/team/:teamId/add' element={<AddParticipantPage />} />
                <Route path='/orgseason/:orgSeasonId/member/:memberId/card-form/:secCode' element={<CardDataFormPage />} />
                <Route path='/orgseason/:orgSeasonId/member/:memberId' element={<MembersPage />} />
                <Route path='/orgseason/:orgSeasonId/leader/:leaderId' element={<LeadersPage />} />
                <Route path='/orgseason/:orgSeasonId/photo-capture' element={<ProfileCapturePage />} />
                <Route path='/orgseason/:orgSeasonId/sponsor' element={<UpdateSponsorPage />} />
                <Route path='/orgseason/:orgSeasonId/sponsor/:sponsorId' element={<UpdateSponsorPage />} />
                <Route path='/orgseason/:orgSeasonId/admin' element={<OrgSeasonAdminPage />} />
                <Route path='/admin' element={<AdminPage />} />
                <Route path='/user/:userId' element={<UpdateUserPage />} />
              </Routes>
            </BrowserRouter>
          </Grid>

          <Grid item xs={1} />
        </Grid>
      </Box>
      <Footer />
    </>
  );
}


