import axios from 'axios';
import { AddImageCommand, ChangeTeamCommand, FileDataAsString, IdResponse, ImageResponse, UpdateIdentifierCommand, UpdateMemberNameCommand } from './models/common';
import { AddDocumentCommand, CreateMemberCommand, Member, MemberDocument, UpdateMemberMetadataCommand } from './models/member';

export async function createMember(bearerToken: string, createMember: CreateMemberCommand): Promise<IdResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member`;
  const response = await axios.post(baseUrl + endpoint, createMember, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as IdResponse;
}

export async function updateMemberProfileImage(bearerToken: string, memberId: string, imageData: AddImageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/profile`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberActionImage(bearerToken: string, memberId: string, imageData: AddImageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/action`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberName(bearerToken: string, memberId: string, memberName: UpdateMemberNameCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/name`;
  await axios.put(baseUrl + endpoint, memberName, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberIdentifer(bearerToken: string, memberId: string, identifier: UpdateIdentifierCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/identifier`;
  await axios.put(baseUrl + endpoint, identifier, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberMetaData(bearerToken: string, memberId: string, metaData: UpdateMemberMetadataCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/metadata`;
  await axios.put(baseUrl + endpoint, metaData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberAssignTeam(bearerToken: string, memberId: string, changeTeamCommand: ChangeTeamCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/assign-team`;
  await axios.put(baseUrl + endpoint, changeTeamCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberRemoveTeam(bearerToken: string, memberId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/remove-team`;
  await axios.put(baseUrl + endpoint, null, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function deleteMember(bearerToken: string, memberId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getMember(bearerToken: string, memberId: string): Promise<Member> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as Member;
}

export async function getMemberProfileImage(bearerToken: string, memberId: string): Promise<ImageResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/profile`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImageResponse;
}

export async function getMemberActionImage(bearerToken: string, memberId: string): Promise<ImageResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/action`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImageResponse;
}

export async function addMemberDocumentApi(bearerToken: string, memberId: string, docRequest: AddDocumentCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/document`;
  await axios.post(baseUrl + endpoint, docRequest, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getMemberDocumentApi(bearerToken: string, memberId: string, documentId: string): Promise<MemberDocument> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/document/${documentId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as MemberDocument;
}

export async function deleteMemberDocumentApi(bearerToken: string, memberId: string, documentId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/document/${documentId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function viewMemberTradingCards(bearerToken: string, memberId: string, layout: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/trading-cards/${layout}/view`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateMemberTradingCards(bearerToken: string, memberId: string, layout: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/trading-cards/${layout}/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateBinderSheet(bearerToken: string, memberId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberId}/binder-sheet/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

