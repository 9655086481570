import { Box, Grid, TextField, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Leader, LeaderMetaData } from '../../api/models/leader';
import dayjs from 'dayjs';

const defaultMetaData = {
    "nickName": '',
    "school": '',
    "birthplace": '',
    "birthdate": new Date(),
    "hobby": '',
    "favoriteTeam": '',
    "uniqueFact": ''
}

export interface LeaderTabProps {
    leader: Leader,
    isLoading: boolean,
    onSubmit: (metaData: LeaderMetaData) => void
}

export default function LeaderCardDataTab(props: LeaderTabProps) {

    const [metaData, setMetaData] = React.useState<LeaderMetaData>(defaultMetaData);
    const [birthDateString, setBirthDateString] = React.useState<string>('');

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();


    React.useEffect(() => {
        if (props.leader?.metaData) {
            setMetaData(props.leader.metaData);
        }

        if (props.leader?.metaData?.birthdate) {
            setBirthDateString(dayjs(props.leader.metaData.birthdate).format('MM/DD/YYYY'));
        }


    }, []);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        console.log(`setting ${id} to ${value}`);
        setMetaData({ ...metaData, [id]: value });
    };

    const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e) {
            const { value } = e.target;
            setBirthDateString(value);
            const date = dayjs(value);
            const birthdate = date.toDate();
            setMetaData({ ...metaData, 'birthdate': birthdate });
        }

    };

    const updateMetaData = async () => {
        console.log(`updating meta data`, metaData);
        props.onSubmit(metaData);
    };

    return (
        <Grid container spacing={1}>

            <Grid item xs={4}>
                <Box>
                    <TextField id="nickName"
                        label="NickName"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.nickName}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box>
                    <TextField id="school"
                        label="School"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.school}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="birthdate"
                        label="Birthdate"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={birthDateString}
                        style={{ width: 275 }}
                        onChange={handleBirthdayChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="birthplace"
                        label="Birthplace"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.birthplace}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box>
                    <TextField id="hobby"
                        label="Hobby"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.hobby}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="favoriteTeam"
                        label="FavoriteTeam"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.favoriteTeam}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="uniqueFact"
                        label="Unique Fact"
                        variant="outlined"
                        size="small"
                        helperText='40 character limit'
                        inputProps={{ maxLength: 40 }}
                        value={metaData.uniqueFact}
                        style={{ width: 350 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            
            <Grid item xs={4}>
                {isAuthenticated && !props.isLoading && <Button variant='contained' onClick={updateMetaData}>Update MetaData</Button>}
                {props.isLoading && <CircularProgress />}
            </Grid>
        </Grid>
    )
}