import React, { useEffect, useState } from "react";
import { Grid, Button, CircularProgress, Box, FormGroup, FormControlLabel } from '@mui/material';
import { getSingleOrgSeasonApi, addSponsorApi, updateSponsorApi } from '../../api/orgSeasonApi';
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from 'react-router-dom';
import { UploadImageButton } from "../../shared/uploadImageButton";
import { OrgHeader } from "../../shared/orgHeader";
import { ErrorHeader } from "../../shared/errorHeader";
import { OrgSeason, Sponsor } from "../../api/models/orgSeason";
import { BasicTextBox } from "../../shared/basicTextBox";
import { useApi } from "../../hooks/useApi";
import Checkbox from '@material-ui/core/Checkbox';
import { BasicButton } from "../../shared/basicButton";
import { BasicCheckBox } from "../../shared/basicCheckBox";
import { BasicBackButton } from "../../shared/basicBackButton";

export const UpdateSponsorPage: React.FC = (): JSX.Element => {

    const [orgSeason, setOrgSeason] = useState<OrgSeason | null>(null);
    const [currentSponsor, setCurrentSponsor] = useState<Sponsor | null>(null);
    const [sponsorId, setSponsorId] = useState<string | undefined>(undefined);

    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState('');

    const [description, setDescription] = React.useState('');
    const [descriptionError, setDescriptionError] = React.useState('');

    const [website, setWebsite] = React.useState('');
    const [websiteError, setWebsiteError] = React.useState('');

    const [contact, setContact] = React.useState('');
    const [contactError, setContactError] = React.useState('');

    const [onRoster, setOnRoster] = React.useState<boolean>(false);
    const [onCard, setOnCard] = React.useState<boolean>(false);

    const [logo, setLogo] = React.useState('');
    const [newLogo, setNewLogo] = React.useState('');

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getRequest = useApi(getSingleOrgSeasonApi);
    const updateRequest = useApi(updateSponsorApi);
    const addRequest = useApi(addSponsorApi);

    const params = useParams();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        initPage();
    }, [getAccessTokenSilently]);


    useEffect(() => {
        if (getRequest.data) {
            setOrgSeason(getRequest.data);
            prePopulateEdit(getRequest.data.sponsors);
        }
    }, [getRequest.data]);


    useEffect(() => {
        clearPage();
    }, [updateRequest.data]);


    async function initPage() {
        if (!params.orgSeasonId) {
            setErrorMessage('OrgSeason is missing');
        }

        await getRequest.request(params.orgSeasonId);
    }

    function prePopulateEdit(sponsors: Sponsor[]) {

        if (params.sponsorId) {
            if (sponsors && sponsors.length > 0) {
                setSponsorId(params.sponsorId);

                const findSponsor = sponsors.find(x => x.id === params.sponsorId);
                if (findSponsor) {
                    setCurrentSponsor(findSponsor);
                    setName(findSponsor.name);
                    setDescription(findSponsor.description);
                    setWebsite(findSponsor.website);
                    setContact(findSponsor.contact);
                    setLogo(findSponsor.logoLink);
                    setOnCard(findSponsor.onCard);
                    setOnRoster(findSponsor.onRoster);
                }
                else {
                    setErrorMessage('Invalid sponsorId - no sponsors with this ID');
                }
            }
            else {
                setErrorMessage('Invalid sponsorId - no sponsors');
            }
        }


    }

    //event handlers
    const onChangeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(e.target.value);
    }

    const onChangeDescription = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    }

    const onChangeWebsite = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setWebsite(e.target.value);
    }

    const onChangeContact = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setContact(e.target.value);
    }

    const onChangeRoster = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOnRoster(checked);
    }

    const onChangeCard = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOnCard(checked);
    }

    const onChangeLogo = async (selectedImage: string): Promise<void> => {
        setLogo(selectedImage);
        setNewLogo(selectedImage);
    }

    async function addSponsor() {

        const addSponsorCommand = { name: name, description, contact, website, logo: newLogo, isActive: true, onRoster, onCard };
        if (sponsorId) {
            await updateRequest.request(params.orgSeasonId!, sponsorId!, addSponsorCommand);
        }
        else {
            await addRequest.request(params.orgSeasonId!, addSponsorCommand);
        }


    }

    const clearPage = () => {
        setName('');
        setDescription('');
        setWebsite('');
        setContact('');
        setLogo('');
    }

    const mainContent = () => {

        return (
            <Box>
                <h1>{sponsorId ? 'Update Sponsor' : 'Add Sponsor'}</h1>

                <Grid item container spacing={2} direction='column'>
                    <Grid item>

                        <BasicTextBox id='txtName'
                            label='Name*'
                            errorMessage={nameError}
                            required
                            maxLength={20}
                            width={400}
                            value={name}
                            onChange={onChangeName} />

                    </Grid>

                    <Grid item>

                        <BasicTextBox id='txtDescription'
                            label='Description'
                            errorMessage={descriptionError}
                            maxLength={40}
                            width={400}
                            value={description}
                            onChange={onChangeDescription} />
                    </Grid>

                    <Grid item>

                        <BasicTextBox id='txtWebsite'
                            label='Website'
                            errorMessage={websiteError}
                            maxLength={40}
                            width={400}
                            value={website}
                            onChange={onChangeWebsite} />

                    </Grid>

                    <Grid item>

                        <BasicTextBox id='txtContact'
                            label='Contact'
                            errorMessage={contactError}
                            maxLength={40}
                            width={400}
                            value={contact}
                            onChange={onChangeContact} />

                    </Grid>

                    <Grid item>
                        <BasicCheckBox checked={onRoster} onChange={onChangeRoster} label='On Roster' />
                        <BasicCheckBox checked={onCard} onChange={onChangeCard} label='On Card' />

                    </Grid>

                    <Grid item>
                        <Box>
                            {logo && <img src={logo} height={120} width={180} alt='logo' style={{ border: '1px solid #909090' }} />}
                            <UploadImageButton id="uploadProfile" onChange={onChangeLogo} aspectRatio={1.5} />
                        </Box>
                    </Grid>


                    <Grid item>
                        {updateRequest.loading && <CircularProgress />}
                        {addRequest.loading && <CircularProgress />}

                        {!updateRequest.loading && !addRequest.loading &&
                            <BasicButton onClick={addSponsor} labelText="Update" />}

                    </Grid>


                </Grid>

            </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        <Box>{orgSeason && <BasicBackButton labelText='' navigateUrl={`/orgseason/${orgSeason.id}/admin`} />}</Box>
        {isLoading && <CircularProgress />}
        {orgSeason && mainContent()}
    </Box>
    );
}