import DatadogWinston from 'datadog-winston';
import winston from 'winston';
import { v4 as uuid } from 'uuid';
import { LogMessage } from './logMessage';


export class ColbrenLogger {
    private _correlationId: string;
    private _environment: string;
    private _logger: winston.Logger;

    public get correlationId() {
        return this._correlationId;
    }

    constructor() {
        this._environment = process.env.REACT_APP_ENVIRONMENT || '';
        const apiKey = process.env.REACT_APP_DATA_DOG_API_KEY || '';
        const logOptions = {
            apiKey: apiKey,
            hostname: 'Default-Machine',
            service: 'ColbrenSportsApp',
            ddsource: 'nodejs',
        };

        this._correlationId = uuid();
        this._logger = winston.createLogger({});
        this._logger.add(new DatadogWinston(logOptions));
    }

    public logPageRequest(pageName:any, userName?:string){

        const request = {
            requestName: pageName,
            serviceType:'UI'
        }

        const logItem: LogMessage = {
            env: this._environment,
            userName: userName,
            category: 'Page',
            status: 'Info',
            message: { request, dateCreated: Date.now() },
            ddtags: '',
        }

        this._logger.log('info', logItem);
    }

    public logError(message:any, userName:string){
        const logItem: LogMessage = {
            env: this._environment,
            userName: userName,
            category: 'Issue',
            status: 'Error',
            message: { message, dateCreated: Date.now() },
            ddtags: '',
        }

        this._logger.log('issue', logItem);
    }

    public logMessage(message: any, userName: string) {
        const logItem: LogMessage = {
            env: this._environment,
            userName: userName,
            category: 'Message',
            status: 'Info',
            message: { message, dateCreated: Date.now() },
            ddtags: '',
        }

        this._logger.log('info', logItem);
    }

    public logRequest(method: string, url: string, headers: string, userName: string,
        requestName: string, reqBody: string, statusCode: number,
        timingMs: number, resBody: any, serviceType:string) {

        const apiRequest = {
            method: method,
            url: url,
            requestName: requestName,
            reqBody: reqBody,
            serviceType:serviceType
        }


        if (resBody && resBody.data) {
            resBody = {
                name: resBody.name,
                mimeType: resBody.mimeType,
                ext: resBody.ext,
                data: resBody.data.substring(0, 50)
            };
        }

        if (resBody && resBody.image) {
            resBody = {
                image: resBody.image.substring(0, 50)
            };
        }

        const apiResponse = {
            timingMs: timingMs,
            statusCode: statusCode,
            body: resBody
        }

        const apiRequestItem = {
            request: apiRequest,
            response: apiResponse
        }

        const logItem: LogMessage = {
            env: this._environment,
            userName: userName,
            category: 'Request',
            status: 'Info',
            message: apiRequestItem,
            ddtags: '',
        }

        this._logger.log('info', logItem);
    }
}