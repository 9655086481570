import { CourseData } from '../api/models/reportCard';

export const pointScaleTypeOptions: string[] = ['Standard', 'Honors', 'Advanced'];
export const courseTypeOptions: string[] = ['LanguageArts', 'Mathematics', 'SocialStudies', 'Science', 'Humanities'];

const gradeOptionsESNU: string[] = ['E', 'S', 'N', 'U', ' '];
const gradeOptionsABCDF: string[] = ['A', 'B', 'C', 'D', 'F', ' '];
const gradeOptionsABCDU: string[] = ['A', 'B', 'C', 'D', 'U', ' '];
const gradeOptionsABCNU: string[] = ['A', 'B', 'C', 'N', 'U', ' '];

export const getCourseTemplate = (reportCardSource: string, gradeLevel: number): CourseData[] => {
    let template = null;
    if (reportCardSource === "HillsboroughCounty") {
        if (gradeLevel < 6) {
            template = getHillsboroughElementaryTemplate(gradeLevel);
        }
        else {
            template = getHillsboroughMiddleSchoolTemplate(gradeLevel);
        }
    }
    else if (reportCardSource === "PascoCounty") {
        if (gradeLevel < 6) {
            template = getPascoElementaryTemplate(gradeLevel);
        }
        else {
            template = getPascoMiddleSchoolTemplate(gradeLevel);
        }
    }
    else if (reportCardSource === "ABCDF Scale") {
        template = getABCDFTemplate(gradeLevel);
    }
    else if (reportCardSource === "ESNU Scale") {
        template = getESNUTemplate(gradeLevel);
    }
    else {
        template = getABCDFTemplate(gradeLevel);
    }

    return template;
}

function generateDefaultCourses(numCourses: number, courseNames: string[], courseTypes: string[], gradeOptions: string[], numTerms: number): CourseData[] {
    let courses: CourseData[] = [];

    if (!gradeOptions || gradeOptions.length < 2) {
        throw Error('There are no gradeOptions for these courses');
    }

    const pointScale = (gradeOptions.length - 1).toString();
    const bestGrade = gradeOptions[0];


    for (let i = 0; i < numCourses; i++) {

        const grades: string[] = new Array(numTerms);

        for (let i = 0; i < numTerms; i++) {
            grades[i] = bestGrade;
        }

        const newCourse: CourseData = {
            id: i,
            courseName: (courseNames && courseNames[i]) ? courseNames[i] : "",
            courseType: (courseTypes && courseTypes[i]) ? courseTypes[i] : "",
            pointScale: pointScale,
            pointScaleType: 'Standard',
            grades: grades.slice(),
            gradeOptions: gradeOptions.slice(),
            pointScaleOptions: [pointScale]
        };

        courses.push(newCourse);
    }

    return courses;
}

function getHillsboroughElementaryTemplate(gradeLevel: number): CourseData[] {

    const numCourses = 8;
    const courseNames = ['Reading', 'Written Communication', 'Mathematics', 'Science / Health', 'Social Studies', 'Art', 'Music', 'Physical Education'];
    const courseTypes = ['LanguageArts', 'LanguageArts', 'Mathematics', 'Science', 'SocialStudies', 'Humanities', 'Humanities', 'Science'];
    const gradeOptions = (gradeLevel < 3) ? gradeOptionsESNU : gradeOptionsABCNU;
    const numTerms = 4;

    const template: CourseData[] = generateDefaultCourses(numCourses, courseNames, courseTypes, gradeOptions, numTerms);

    return template;
}

function getHillsboroughMiddleSchoolTemplate(gradeLevel: number): CourseData[] {

    const numCourses = 9;
    const courseNames: string[] = [];
    const courseTypes: string[] = [];
    const gradeOptions = gradeOptionsABCDF;
    const numTerms = 4;

    const template: CourseData[] = generateDefaultCourses(numCourses, courseNames, courseTypes, gradeOptions, numTerms);

    return template;
}

function getPascoElementaryTemplate(gradeLevel: number): CourseData[] {

    const numCourses1 = 5;
    const courseNames1 = ['Reading', 'Writing', 'Mathematics', 'Science / Health', 'Social Studies'];
    const courseTypes1 = ['LanguageArts', 'LanguageArts', 'Mathematics', 'Science', 'SocialStudies'];
    const gradeOptions1 = (gradeLevel < 3) ? gradeOptionsESNU : gradeOptionsABCDU;
    const numTerms1 = 4;

    const template1: CourseData[] = generateDefaultCourses(numCourses1, courseNames1, courseTypes1, gradeOptions1, numTerms1);

    const numCourses2 = 3;
    const courseNames2 = ['Art', 'Music', 'Physical Education'];
    const courseTypes2 = ['Humanities', 'Humanities', 'Science'];
    const gradeOptions2 = gradeOptionsESNU;
    const numTerms2 = 4;

    const template2: CourseData[] = generateDefaultCourses(numCourses2, courseNames2, courseTypes2, gradeOptions2, numTerms2);

    let template = template1.concat(template2);

    for (let i = 0; i < template.length; i++) {
        template[i].id = i;
    }

    return template;
}

function getPascoMiddleSchoolTemplate(gradeLevel: number): CourseData[] {

    const template = getABCDFTemplate(gradeLevel);

    return template;
}

function getABCDFTemplate(gradeLevel: number): CourseData[] {

    const numCourses = 9;
    const courseNames: string[] = [];
    const courseTypes: string[] = [];
    const gradeOptions = gradeOptionsABCDF;
    const numTerms = 4;

    const template: CourseData[] = generateDefaultCourses(numCourses, courseNames, courseTypes, gradeOptions, numTerms);

    return template;
}

function getESNUTemplate(gradeLevel: number): CourseData[] {

    const numCourses = 9;
    const courseNames: string[] = [];
    const courseTypes: string[] = [];
    const gradeOptions = gradeOptionsESNU;
    const numTerms = 4;

    const template: CourseData[] = generateDefaultCourses(numCourses, courseNames, courseTypes, gradeOptions, numTerms);

    return template;
}



