import React, { useEffect, useState } from "react";
import { Grid, Box, CircularProgress, Button } from '@mui/material';
import { BasicCard } from "../../shared/basicCard";
import { useApi } from "../../hooks/useApi";
import { BasicErrorLabel } from "../../shared/basicErrorLabel";
import { exportMembersApi, getSampleImportFileApi, importMembersApi } from "../../api/orgSeasonApi";
import { BasicButton } from "../../shared/basicButton";
import { OrgSeason } from "../../api/models/orgSeason";
import { ImportResult } from "../../api/models/common";
import { getSingleOrgSeasonApi } from "../../api/orgSeasonApi";
import { downloadBase64AsFile } from "../../utils/binaryHelper";
import { UploadFileButton } from "../../shared/uploadFileButton";
import { ImportResultDisplay } from "./importResultDisplay";
import { BasicCheckBox } from "../../shared/basicCheckBox";

export interface ManageImportsPanelProps {
    orgSeasonId: string
}

export function ManageImportsPanel(props: ManageImportsPanelProps) {
    const [orgSeason, setOrgSeason] = useState<OrgSeason | undefined>(undefined);

    const [importResult, setImportResult] = useState<ImportResult | undefined>(undefined);

    const getOrgSeasonRequest = useApi(getSingleOrgSeasonApi);
    const sampleRequest = useApi(getSampleImportFileApi);
    const importRequest = useApi(importMembersApi);
    const exportRequest = useApi(exportMembersApi);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getOrgSeasonRequest.data) {
            setOrgSeason(getOrgSeasonRequest.data);
        }

    }, [getOrgSeasonRequest.data]);

    useEffect(() => {
        if (sampleRequest.data) {
            downloadBase64AsFile(sampleRequest.data.data, sampleRequest.data.name);
        }

    }, [sampleRequest.data]);

    useEffect(() => {
        if (importRequest.data) {
            console.log('import Request', importRequest.data);
            setImportResult(importRequest.data);

        }

    }, [importRequest.data]);

    async function initPage() {
        await getOrgSeasonRequest.request(props.orgSeasonId);
    }

    async function requestSampleData() {
        await sampleRequest.request(props.orgSeasonId);
    }

    async function importData(selectedFile: string, fileName: string, mimeType: string, size: number) {
        if (selectedFile) {
            const importItem = { csv: selectedFile, usePreviousProfiles: false };
            await importRequest.request(props.orgSeasonId, importItem);
        }
    }

    return (<>
        <Grid container spacing={3} alignItems="center">
            <Grid item><h3>Manage Imports</h3></Grid>
        </Grid>
        <BasicCard>

            <Box>
                <Grid container spacing={1} direction='column'>
                    <Grid>
                        {sampleRequest.loading && <CircularProgress />}
                        {!sampleRequest.loading && <BasicButton labelText='Download Sample' onClick={requestSampleData} />}

                    </Grid>

                    <Grid>
                        {importRequest.loading && <CircularProgress />}
                        {!importRequest.loading && <UploadFileButton id="uploadDocument" accept=".csv" onChange={importData} />}
                    </Grid>

                </Grid>
                {importResult && <ImportResultDisplay result={importResult} />}
                {sampleRequest.error && <BasicErrorLabel errors={[sampleRequest.error]} />}
                {importRequest.error && <BasicErrorLabel errors={[importRequest.error]} />}
            </Box>

        </BasicCard>
    </>
    );
}