import { Box, CircularProgress, Grid } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCardDataMemberApi, updateCardDataMemberApi } from "../../api/cardDataApi";

import { Member } from "../../api/models/member";
import { useApi } from "../../hooks/useApi";
import { BasicButton } from "../../shared/basicButton";
import { BasicTextBox } from "../../shared/basicTextBox";

export function CardDataFormPage() {

    const [member, setMember] = useState<Member | null>(null);

    const [nickName, setNickName] = useState<string>('');
    const [school, setSchool] = useState<string>('');
    const [seasonsExp, setSeasonsExp] = useState<string>('');
    const [favPos, setFavPos] = useState<string>('');
    const [height, setHeight] = useState<string>('');
    const [weight, setWeight] = useState<string>('');
    const [birthDate, setBirthDate] = useState<string>('');
    const [birthPlace, setBirthPlace] = useState<string>('');
    const [hobby, setHobby] = useState<string>('');
    const [favTeam, setFavTeam] = useState<string>('');
    const [uniqueFact, setUniqueFact] = useState<string>('');
    const [gradeClass, setGradeClass] = useState<string>('');

    const [memberSecCode, setMemberSecCode] = useState<string | undefined>(undefined);

    const params = useParams();
    const getMemberRequest = useApi(getCardDataMemberApi);
    const updateMemberRequest = useApi(updateCardDataMemberApi);

    useEffect(() => {
        if (params.secCode && params.memberId) {

            console.log('fetch data');
            fetchData();
        }
        else {
            //todo: display an error message
        }

    }, [params.secCode, params.orgSeasonId]);

    useEffect(() => {
        if (getMemberRequest.data) {
            console.log('init page');
            initPage(getMemberRequest.data);

        }
    }, [getMemberRequest.data]);

    useEffect(() => {
        if (updateMemberRequest.status) {
            //todo: some way to indicate that it was a success

        }
    }, [updateMemberRequest.status]);

    async function fetchData() {
        getMemberRequest.request(params.orgSeasonId, params.secCode);
    }

    async function initPage(member: Member) {
        setMember(member);

        if (member.metaData) {
            setNickName(member.metaData.nickName ?? '');
            setSchool(member.metaData.school ?? '');
            setSeasonsExp(member.metaData.seasonsExp?.toString() ?? '');
            setFavPos(member.metaData.favoritePosition ?? '');
            setHeight(member.metaData.height?.toString() ?? '');
            setWeight(member.metaData.weight?.toString() ?? '');
            setBirthDate(member.metaData.birthdate?.toString() ?? '');
            setBirthPlace(member.metaData.birthplace ?? '');
            setHobby(member.metaData.hobby ?? '');
            setFavTeam(member.metaData.favoriteTeam ?? '');
            setUniqueFact(member.metaData.uniqueFact ?? '');
            setGradeClass(member.metaData.gradeClass ?? '');
        }
    }

    async function updateData() {
        const updateCommand = {
            nickName,
            seasonsExp,
            school,
            birthdate: new Date(),
            height,
            weight,
            birthplace: birthPlace,
            hobby,
            favoriteTeam: favTeam,
            favoritePosition: favPos,
            uniqueFact,
            gradeClass
        };

        updateMemberRequest.request(member!.id, updateCommand);
    }

    function mainContent() {
        return (
            <Box>
                <h1>{member!.firstName} {member!.lastName} Card Data</h1>
                <p> <span>Birthdate: </span><span> {dayjs(birthDate).format('MM/DD/YYYY')}</span></p>
                {updateMemberRequest.error && <p>{updateMemberRequest.error}</p>}
                <Grid container direction='column' spacing={2}>

                    <Grid item>
                        <BasicTextBox id='txtNickName'
                            label='NickName'
                            maxLength={20}
                            width={275}
                            value={nickName}
                            onChange={(e) => setNickName(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtSchool'
                            label='School'
                            maxLength={20}
                            width={275}
                            value={school}
                            onChange={(e) => setSchool(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtSeasonsExp'
                            label='Number Seasons Exp'
                            maxLength={20}
                            width={275}
                            value={seasonsExp}
                            onChange={(e) => setSeasonsExp(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtHeight'
                            label='Height'
                            maxLength={20}
                            width={275}
                            value={height}
                            onChange={(e) => setHeight(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtWeight'
                            label='Height'
                            maxLength={20}
                            width={275}
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtFavPos'
                            label='Favorite Position'
                            maxLength={20}
                            width={275}
                            value={favPos}
                            onChange={(e) => setFavPos(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtBirthPlace'
                            label='BirthPlace'
                            maxLength={20}
                            width={275}
                            value={birthPlace}
                            onChange={(e) => setBirthPlace(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtHobby'
                            label='Hobby'
                            maxLength={20}
                            width={275}
                            value={hobby}
                            onChange={(e) => setHobby(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtFavTeam'
                            label='Favorite Team'
                            maxLength={20}
                            width={275}
                            value={favTeam}
                            onChange={(e) => setFavTeam(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtUniqueFact'
                            label='UniqueFact'
                            maxLength={55}
                            width={500}
                            value={uniqueFact}
                            onChange={(e) => setUniqueFact(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicTextBox id='txtGradeClass'
                            label='GradeClass'
                            maxLength={55}
                            width={500}
                            value={gradeClass}
                            onChange={(e) => setGradeClass(e.target.value)} />
                    </Grid>

                    <Grid item>
                        <BasicButton onClick={updateData} labelText="Update" />
                    </Grid>

                </Grid>
            </Box>);
    }

    return (
        <Box>
            {getMemberRequest.loading && <CircularProgress />}
            {member && mainContent()}
        </Box>
    )

}