import React from 'react';
import { Grid, Box, Tabs, Tab } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { TabPanel } from '../../shared/tabPanel';
import LeaderCardDataTab from './leaderCardDataTab';
import LeaderActionImageTab from './leaderActionImageTab';
import { Leader, LeaderMetaData } from '../../api/models/leader';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface LeaderTabProps {
    leader: Leader | undefined,
    isLoadingCardData: boolean,
    isLoadingAction: boolean,
    onSubmitMetaData: (metaData: LeaderMetaData) => Promise<void>
    onSubmitChangeAction: (selectedImage: string) => Promise<void>
}


export default function LeaderTabs(props: LeaderTabProps) {
    const [tabValue, setTabValue] = React.useState(0);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();


    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleMetaDataUpdate = (metaData: LeaderMetaData) => {
        props.onSubmitMetaData(metaData);
    }



    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChangeTabs} aria-label="leader tabs">
                        <Tab label="Card Data" {...a11yProps(0)} />
                        <Tab label="Action Image" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    {props.leader && <LeaderCardDataTab leader={props.leader} onSubmit={handleMetaDataUpdate} isLoading={props.isLoadingCardData} />}
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    {props.leader && <LeaderActionImageTab leader={props.leader} onSubmit={props.onSubmitChangeAction} isLoading={props.isLoadingAction} />}
                </TabPanel>

            </Grid>
        </Grid>
    )
};
