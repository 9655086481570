import axios from 'axios';
import { AddImageCommand, ChangeTeamCommand, FileDataAsString, IdResponse, ImageResponse, UpdateIdentifierCommand, UpdateMemberNameCommand } from './models/common';
import { CreateLeaderCommand, Leader, UpdateLeaderMetadataCommand } from './models/leader';

export async function createLeader(bearerToken: string, createLeader: CreateLeaderCommand): Promise<IdResponse>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader`;
  const response = await axios.post(baseUrl + endpoint, createLeader, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as IdResponse;
}

export async function updateLeaderProfileImage(bearerToken: string, leaderId: string, imageData: AddImageCommand): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/profile`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateLeaderActionImage(bearerToken: string, leaderId: string, imageData: AddImageCommand): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/action`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateLeaderName(bearerToken: string, leaderId: string, leaderName: UpdateMemberNameCommand): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/name`;
  await axios.put(baseUrl + endpoint, leaderName, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateLeaderIdentifer(bearerToken: string, leaderId: string, identifier: UpdateIdentifierCommand): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/identifier`;
  await axios.put(baseUrl + endpoint, identifier, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateLeaderMetaData(bearerToken: string, leaderId: string, metaData: UpdateLeaderMetadataCommand): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/metadata`;
  await axios.put(baseUrl + endpoint, metaData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateLeaderAssignTeam(bearerToken: string, leaderId: string, changeTeamCommand: ChangeTeamCommand): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/assign-team`;
  await axios.put(baseUrl + endpoint, changeTeamCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateLeaderRemoveTeam(bearerToken: string, leaderId: string): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/remove-team`;
  await axios.put(baseUrl + endpoint, null, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getLeader(bearerToken: string, leaderId: string): Promise<Leader>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as Leader;
}

export async function deleteLeader(bearerToken: string, leaderId: string): Promise<void>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getLeaderProfileImage(bearerToken: string, leaderId: string): Promise<ImageResponse>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/profile`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImageResponse;
}

export async function getLeaderActionImage(bearerToken: string, leaderId: string): Promise<ImageResponse>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/action`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImageResponse;
}

export async function viewLeaderTradingCards(bearerToken: string, leaderId: string, layout: string): Promise<FileDataAsString>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/trading-cards/${layout}/view`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateLeaderTradingCards(bearerToken: string, leaderId: string, layout: string): Promise<FileDataAsString>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/trading-cards/${layout}/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateBinderSheet(bearerToken: string, leaderId: string): Promise<FileDataAsString>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/leader/${leaderId}/binder-sheet/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}