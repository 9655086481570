import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ManageOrganziationsPanel } from "./manageOrganizationsPanel";
import { ManageUsersPanel } from "./manageUsersPanel";
import { getIsSuperAdmin } from "../../api/userApi";
import { useApi } from "../../hooks/useApi";
import { ColbrenLogger } from "../../logging/colbrenLogger";
import { useAuth0 } from "@auth0/auth0-react";

export function AdminPage() {
    const [hasAccess, setHasAccess] = useState<boolean>(false);

    const getUserRequest = useApi(getIsSuperAdmin);
    const { user } = useAuth0();

    //trigger on page load
    useEffect(() => {
        const logger  = new ColbrenLogger();
        const message={pageName:'Admin Page'};
        logger.logPageRequest(message,user!.name!);
        updatePageData();
    }, []);

    //trigger on page load
    useEffect(() => {
        if (getUserRequest.data) {
            setAccess(getUserRequest.data);
        }
    }, [getUserRequest.data]);

    async function updatePageData() {
        await getUserRequest.request('', "orgSeason");
    }

    async function setAccess(userRole: string): Promise<void> {
        const isSuperAdmin = (userRole === "superadmin");
        setHasAccess(isSuperAdmin);
    }

    const mainContent = () => {

        return (<>
            {hasAccess &&
                <Box>
                    <h3>Organizations</h3>

                    <ManageOrganziationsPanel />


                    <h3>Users</h3>
                    <ManageUsersPanel />
                </Box>}
            {!hasAccess && <h1>You dont have access to view this page</h1>}
        </>
        );
    }

    return (<>
        {getUserRequest.error && <p>{getUserRequest.error}</p>}
        {mainContent()}
    </>
    );

}