import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Button, Typography, CircularProgress, Box } from '@mui/material';
import { getSingleOrgSeasonApi } from '../../api/orgSeasonApi';
import { useAuth0 } from "@auth0/auth0-react";

import { useParams, useNavigate } from 'react-router-dom';
import { CreateMemberCommand } from "../../api/models/member";
import { OrgSeason } from "../../api/models/orgSeason";
import { createMember } from '../../api/memberApi';
import { Team } from "../../api/models/team";
import { CreateLeaderCommand } from "../../api/models/leader";
import { createLeader } from "../../api/leaderApi";
import { OrgHeader } from "../../shared/orgHeader";
import { ErrorHeader } from "../../shared/errorHeader";


export function AddParticipantPage() {

    const [orgSeason, setOrgSeason] = useState<OrgSeason | null>(null);
    const [team, setTeam] = useState<Team | null>(null);

    const [memberType, setMemberType] = React.useState('Athlete');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [jerseyNumber, setJerseyNumber] = React.useState('');

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const getOrgSeason = async () => {

            try {

                if (!params.orgSeasonId) {
                    throw Error('ID is missing');
                }

                let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });


                getSingleOrgSeasonApi(accessToken, params.orgSeasonId)
                    .then((orgSeason) => {
                        setOrgSeason(orgSeason);
                        const findTeam = orgSeason.teams.find(x => x.id === params.teamId!);
                        if (!findTeam) {
                            throw Error('Cannot find team');
                        }
                        setTeam(findTeam);

                    })
                    .catch((error) => {
                        if (error.response?.data) {
                            setErrorMessage(error.response.data.message);
                        }
                        else {
                            setErrorMessage(error.message);
                        }

                    });


            } catch (e: any) {
                console.log(e.message);
            }

        }
        getOrgSeason();



    }, [getAccessTokenSilently]);

    //event handlers
    const onChangeMemberType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMemberType(e.target.value);
    }

    const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstName(e.target.value);
    }

    const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLastName(e.target.value);
    }

    const onChangeJerseyNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setJerseyNumber(e.target.value);
    }

    const addMember = async () => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

        console.log('Adding Member');

        if (memberType === "Athlete") {
            const addMemberItem: CreateMemberCommand = {
                orgSeasonId: orgSeason!.id,
                firstName: firstName,
                lastName: lastName,
                identifier: jerseyNumber,
                teamId: params.teamId!
            };

            setIsLoading(true);
            try {

                await createMember(accessToken, addMemberItem);
                clearPage();
                navigate(`/orgseason/${params.orgSeasonId}/team/${params.teamId}`);
            }
            catch (err) {
                debugger;
                console.log(err);
            }
            finally {
                setIsLoading(false);
            }
        }
        else {
            const addLeaderItem: CreateLeaderCommand = {
                orgSeasonId: orgSeason!.id,
                firstName: firstName,
                lastName: lastName,
                title: memberType,
                displayOrder: 5,
                teamId: params.teamId!
            };

            setIsLoading(true);
            await createLeader(accessToken, addLeaderItem);

            setIsLoading(false);
            clearPage();
        }

    }

    const clearPage = () => {
        setFirstName('');
        setLastName('');
        setJerseyNumber('');

    }

    const mainContent = () => {

        return (
            <Box>
                <h1>{team?.name} Add Member</h1>

                <Grid item container spacing={2} direction='column'>
                    <Grid item>
                        <TextField select
                            label="Select"
                            value={memberType}
                            size="small"
                            onChange={onChangeMemberType}
                            helperText="Select a member type">
                            <MenuItem key='Athlete' value='Athlete'>Athlete</MenuItem>
                            <MenuItem key='Head Coach' value='Head Coach'>Head Coach</MenuItem>
                            <MenuItem key='Asst Coach' value='Asst Coach'>Asst Coach</MenuItem>
                            <MenuItem key='Team Parent' value='Team Mom'>Team Parent</MenuItem>
                            <MenuItem key='Equipment Manager' value='Equipment Manager'>Equipment Manager</MenuItem>
                            <MenuItem key='Board' value='Board'>Board</MenuItem>
                            <MenuItem key='Other' value='Other'>Other</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item>
                        <TextField id="txtFirstName"
                            label="FirstName"
                            variant="outlined"
                            size="small"
                            value={firstName}
                            onChange={e => onChangeFirstName(e)}
                            inputProps={{ maxLength: 20 }}
                            style={{ width: 275 }}
                        />
                    </Grid>

                    <Grid item>
                        <TextField id="txtLastName"
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            value={lastName}
                            inputProps={{ maxLength: 20 }}
                            style={{ width: 275 }}
                            onChange={e => onChangeLastName(e)} />
                    </Grid>
                    {(memberType === "Athlete") &&
                        <Grid item>
                            <TextField id="txtJerseyNumber"
                                label="Jersey Number"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 2 }}
                                style={{ width: 200 }}
                                value={jerseyNumber}
                                onChange={e => onChangeJerseyNumber(e)} />
                        </Grid>
                    }

                    <Grid item>
                        {isAuthenticated && !isLoading &&
                            <Button onClick={e => addMember()} variant="contained" color="primary">
                                Add
                            </Button>}
                        {isLoading && <CircularProgress />}

                    </Grid>


                </Grid>

            </Box>
        );
    }

    return (<Box>
        {orgSeason && <OrgHeader orgName={orgSeason.organization.name}
            orgId={orgSeason.organization.id}
            orgSeasonName={orgSeason.name}
            orgSeasonId={orgSeason.id}
            teamName={team?.name}
            teamId={team?.id}
            pageName={`Add Member`} />}
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {team && mainContent()}
    </Box>
    );

}