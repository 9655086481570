import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { downloadBase64AsFile } from "../../utils/binaryHelper";
import { Grid, Box, Button, CircularProgress } from '@mui/material';
import { UploadImageButton } from '../../shared/uploadImageButton';
import { OrgHeader } from "../../shared/orgHeader";
import {ErrorHeader} from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";

import {
    getLeader,
    updateLeaderProfileImage,
    updateLeaderActionImage,
    updateLeaderMetaData,
    updateLeaderIdentifer,
    viewLeaderTradingCards,
    generateLeaderTradingCards,
    deleteLeader,
    updateLeaderName,
    getLeaderProfileImage,
    getLeaderActionImage,
    generateBinderSheet
} from '../../api/leaderApi';
import LeaderTabs from "./leaderTabs";
import { Leader, LeaderMetaData } from "../../api/models/leader";
import { BasicBackButton } from "../../shared/basicBackButton";
import { BasicMenu } from "../../shared/basicMenu";


export function LeadersPage() {

    const [leader, setLeader] = useState<Leader | undefined>(undefined);
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
    const [isLoadingCardData, setIsLoadingCardData] = useState<boolean>(false);
    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(false);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);
    const [isLoadingGenerateCards, setIsLoadingGenerateCards] = useState<boolean>(false);
    const [isLoadingDownloadCards, setIsLoadingDownloadCards] = useState<boolean>(false);
    const [isLoadingDeleteMember, setIsLoadingDeleteMember] = useState<boolean>(false);

    const [leaderProfileImage, setLeaderProfileImage] = useState<string | undefined>(undefined);
    const [leaderActionImage, setLeaderActionImage] = useState<string | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();


    useEffect(() => {
        initPage();
    }, [getAccessTokenSilently]);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.orgSeasonId) {
                throw Error('OrgSeason ID is missing');
            }

            if (!params.leaderId) {
                throw Error('Leader ID is missing');
            }

            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });

            const access = await getAccess(accessToken, params.orgSeasonId, "orgSeason");
            setAccessLevel(access);
            setIsAdmin(access === "admin" || access === "superadmin");

            if (!access) {
                throw Error('You dont have permission to access this data');
            }

            await refreshMember(params.leaderId);
            await refreshMemberProfileImage(params.leaderId);
            await refreshMemberActionImage(params.leaderId);
            setIsLoading(false);


        } catch (error: any) {
            console.log(error);
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    }

    async function refreshMember(memberId: string) {
        const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const member = await getLeader(accessToken, memberId)
        setLeader(member);
    }

    async function refreshMemberProfileImage(memberId: string) {
        const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const imageResponse = await getLeaderProfileImage(accessToken, memberId);
        setLeaderProfileImage(imageResponse.image);

    }

    async function refreshMemberActionImage(memberId: string) {
        const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const imageResponse = await getLeaderActionImage(accessToken, memberId);
        setLeaderActionImage(imageResponse.image);
    }

    async function handleChangeProfile(selectedImage: string) {
        setIsLoadingProfile(true);
        const addImageCommand = { image: selectedImage };
        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        await updateLeaderProfileImage(accessToken, leader!.id, addImageCommand);
        initPage();
    }


    async function handleChangeAction(selectedImage: string): Promise<void> {

        setIsLoadingAction(true);
        const addImageCommand = { image: selectedImage };
        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        await updateLeaderActionImage(accessToken, leader!.id, addImageCommand);
        refreshLeader();
    }



    async function download9UPCardsHandler() {
        downloadCardsHandler('9UP');
    };

    async function download1UPCardsHandler() {
        downloadCardsHandler('1UP');
    };




    async function refreshLeader() {
        initPage();
    }

    async function handleSubmitMemberName(firstName: string, lastName: string): Promise<void> {

        setIsLoadingAction(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const leaderName = { firstName: firstName, lastName: lastName };
            await updateLeaderName(accessToken, leader!.id, leaderName);
        }
        catch (error: any) {
            console.log(error);
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    }

    const handleSubmitMetaData = async (metaData: LeaderMetaData) => {
        if (leader?.id) {

            setIsLoadingCardData(true);
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
            await updateLeaderMetaData(accessToken, leader!.id!, metaData);
            setIsLoadingCardData(false);
            navigate(`/orgseason/${leader.orgSeason.id}/team/${leader.team?.id}`);
        }
    }

    const deleteMemberHandler = async () => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        setIsLoadingDeleteMember(true);
        try{
            await deleteLeader(accessToken, leader!.id!);
            setIsLoadingDeleteMember(false);
            navigate(`/orgseason/${leader!.orgSeason.id}/team/${leader!.team?.id}`);
        }
        catch(error){
            console.log(error);
        }
       
    };

    const generateCardsHandler = async (layout: string) => {

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });

        setIsLoadingGenerateCards(true);

        const cardResponse = await generateLeaderTradingCards(accessToken, leader!.id, layout);
        downloadBase64AsFile(cardResponse.data, cardResponse.name);

        setIsLoadingGenerateCards(false);
    };

    const downloadCardsHandler = async (layout: string) => {

        setIsLoadingDownloadCards(true);

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const cardResponse = await viewLeaderTradingCards(accessToken, leader!.id, layout);
        downloadBase64AsFile(cardResponse.data, cardResponse.name);

        setIsLoadingDownloadCards(false);
    };

    const generateBinderSheetHandler = async () => {

        setIsLoadingDownloadCards(true);

        let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
        const binderSheetResponse = await generateBinderSheet(accessToken, leader!.id);
        downloadBase64AsFile(binderSheetResponse.data, binderSheetResponse.name);

        setIsLoadingDownloadCards(false);
    };

    const menuItems = [
        { title: 'Download Cards', onClick: download9UPCardsHandler },
        { title: 'Download Cards - 1 UP', onClick: download1UPCardsHandler },
    ];

    const generalButtons = () => {
        return (
            <Box>
                <Grid item container spacing={2}>

                    <Grid item>
                        {isLoadingGenerateCards && <CircularProgress />}

                        {!isLoadingGenerateCards &&
                            <Button color="primary"
                                variant="contained"
                                onClick={e => generateCardsHandler('9UP')} >
                                Generate Card
                            </Button>}
                    </Grid>

                    <Grid item>
                        {isLoadingGenerateCards && <CircularProgress />}

                        {!isLoadingGenerateCards &&
                            <Button color="primary"
                                variant="contained"
                                onClick={e => generateCardsHandler('1UP')} >
                                Generate Card (1UP)
                            </Button>
                        }
                    </Grid>

                    <Grid item>
                        {isLoadingGenerateCards && <CircularProgress />}

                        {!isLoadingGenerateCards &&
                            <Button color="primary"
                                variant="contained"
                                onClick={e => downloadCardsHandler('9UP')} >
                                Download Card
                            </Button>}
                    </Grid>

                    <Grid item>
                        {isLoadingGenerateCards && <CircularProgress />}

                        {!isLoadingGenerateCards &&
                            <Button color="primary"
                                variant="contained"
                                onClick={e => downloadCardsHandler('1UP')} >
                                Download Cards (1UP)
                            </Button>
                        }
                    </Grid>

                    <Grid item>
                        {isLoadingGenerateCards && <CircularProgress />}

                        {!isLoadingGenerateCards &&
                            <Button color="primary"
                                variant="contained"
                                onClick={e => generateBinderSheetHandler()} >
                                Generate Binder Sheet
                            </Button>
                        }
                    </Grid>

                    <Grid item>
                        {isLoadingDeleteMember && <CircularProgress />}

                        {!isLoadingDeleteMember &&
                            <Button color="error"
                                variant="contained"
                                onClick={e => deleteMemberHandler()} >
                                Delete Member
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>
        );
    }




    const mainContent = () => {

        return (
            <Box>
                <h1>{leader?.firstName}  {leader?.lastName} - ({leader?.team?.name})</h1>

                {generalButtons()}
                <br />
                {leaderProfileImage && <img src={leaderProfileImage} height={200} alt='profile' />}
                <Box>
                    <UploadImageButton id="uploadProfile" onChange={handleChangeProfile} maxWidth={500} maxHeight={500} aspectRatio={1} />
                </Box>

                <br />

                <LeaderTabs leader={leader}
                    onSubmitMetaData={handleSubmitMetaData}
                    onSubmitChangeAction={handleChangeAction}
                    isLoadingAction={isLoadingAction}
                    isLoadingCardData={isLoadingCardData} />
            </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        <Grid container>
            <Grid item>{leader && <BasicBackButton labelText='' navigateUrl={`/orgseason/${leader.orgSeason.id}/team/${leader.team?.id}`} />}</Grid>
            <Grid item><BasicMenu items={menuItems} /></Grid>
        </Grid>
        {isLoading && <CircularProgress />}
        {leader && mainContent()}
    </Box>
    );

}