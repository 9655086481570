import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Button, CircularProgress, Box } from '@mui/material';
import { getSingleOrgSeasonApi } from '../../api/orgSeasonApi';
import { LinkTile } from "../../shared/linkTile";
import { useParams, useNavigate } from 'react-router-dom';
import { OrgSeason } from "../../api/models/orgSeason";
import { Team } from "../../api/models/team";
import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import { OrgHeader } from "../../shared/orgHeader";
import { ColbrenLogger } from "../../logging/colbrenLogger";
import { BasicMenu } from "../../shared/basicMenu";
import { BasicBackButton } from "../../shared/basicBackButton";

const OrgSeasonPage: React.FC = (): JSX.Element => {

    const [orgSeason, setOrgSeason] = useState<OrgSeason | undefined>(undefined);
    const [divisions, setDivisions] = useState<Map<string, Team[]> | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const logger = new ColbrenLogger();
        const pageName = 'Org Season Page';
        logger.logPageRequest(pageName, user!.name!);

        const initPage = async () => {
            try {
                setIsLoading(true);
                setErrorMessage('');

                if (!params.orgSeasonId) {
                    throw Error('OrgSeason Id is missing');
                }

                let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

                const access = await getAccess(accessToken, params.orgSeasonId, "orgSeason");
                setAccessLevel(access);
                setIsAdmin(access === "admin" || access === "superadmin");

                if (!access) {
                    throw Error('You dont have permission to access this data');
                }

                const orgSeason = await getSingleOrgSeasonApi(accessToken, params.orgSeasonId);
                setOrgSeason(orgSeason);
                const teams = orgSeason.teams;
                const divisionList = groupBy<string, Team>(teams, team => team.division);
                setDivisions(divisionList);

                setIsLoading(false);

            }
            catch (error: any) {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.message);
                }
                else {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            }

        }

        initPage();

    }, [getAccessTokenSilently]);

    function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
            var key = grouper(item)

            if (!store.has(key)) {
                store.set(key, [item])
            } else {
                store.get(key)!.push(item)
            }
            return store
        }, new Map<K, V[]>())
    }

    const adminPageHandler = () => {
        if (isAuthenticated && orgSeason) {
            navigate(`/orgseason/${orgSeason.id}/admin`);
        }
    };

    function navigateProfilePhotoPageHandler() {
        if (orgSeason) {
            navigate(`/orgseason/${orgSeason.id}/photo-capture`);
        }

    }

    function renderHeader() {
        return (
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                <Grid item><h1>{orgSeason!.name}</h1></Grid>
                {isAdmin && <Grid item> <Button color="primary"
                    variant="contained"
                    onClick={e => adminPageHandler()} >
                    Admin
                </Button></Grid>}
                <Grid item>
                    <Button color="primary"
                        variant="contained"
                        onClick={e => navigateProfilePhotoPageHandler()} >
                        Update Profile Photos
                    </Button>
                </Grid>

            </Grid>
        );
    }

    function renderTeams() {
        return (
            <Box>
                {orgSeason && divisions && Array.from(divisions.keys()).map((division) =>
                    <Grid item container direction='column'>
                        <Grid item><h3>{division}</h3></Grid>

                        <Grid item container>

                            {divisions.get(division)?.map(team =>
                                <Grid item>
                                    <LinkTile firstName={team.name}
                                        key={team.id}
                                        linkUrl={`/orgSeason/${orgSeason?.id}/team/${team.id}`}
                                        imageUrl={orgSeason.logoLink} />
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                )}
            </Box>
        );
    }

    const mainContent = () => {

        return (
            <Box>
                {renderHeader()}
                {orgSeason && divisions && renderTeams()}
            </Box>
        );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
            <Box>
            {orgSeason?.organization && <BasicBackButton labelText='' navigateUrl={`/orgs/${orgSeason.organization.id}`} />}
            </Box>



        {isLoading && <CircularProgress />}
        {orgSeason && mainContent()}
    </Box>
    );

}

export { OrgSeasonPage }