import { Box, Grid } from '@mui/material';
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { UploadImageButton } from '../../shared/uploadImageButton';
import { Leader } from '../../api/models/leader';

export interface LeaderTabProps {
    leader: Leader | null,
    isLoading: boolean,
    onSubmit: (selectedImage: string) => Promise<void>
}

export default function LeaderActionImageTab(props: LeaderTabProps) {

    const [selectedImage, setSelectedImage] = React.useState<string>('');
    const { isAuthenticated } = useAuth0();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item>
                    {props.leader?.actionLink && <img src={props.leader?.actionLink} height={300} alt='action' />}
                </Grid>

                <Grid item>
                    <Box>
                        <label>Select Action Image</label>
                        {isAuthenticated && <UploadImageButton id="uploadAction" onChange={props.onSubmit} maxWidth={500} maxHeight={500} aspectRatio={1} />}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}