import React from 'react';
import { Grid, Box, Tabs, Tab } from '@mui/material';
import MemberInfoTab from './memberInfoTab';
import MemberCardDataTab from './memberCardDataTab';
import MemberDocumentTab from './memberDocumentTab';
import MemberActionImageTab from './memberActionImageTab';
import { Member, MemberMetaData } from '../../api/models/member';
import { TabPanel } from '../../shared/tabPanel';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface MemberTabProps {
    member: Member | undefined,
    actionImage: string | undefined,
    isLoadingCardData: boolean,
    isLoadingInfo: boolean,
    isLoadingDocument: boolean,
    isLoadingAction: boolean,
    onUpdate: () => void,
    onSubmitJerseyNumber: (identifier: string) => void,
    onSubmitMemberName: (firstName: string, lastName: string) => void
    onSubmitMetaData: (metaData: MemberMetaData) => Promise<void>,
    onSubmitChangeAction: (selectedImage: string) => Promise<void>
}

export default function MemberTabs(props: MemberTabProps) {
    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleMemberNameUpdate = (firstName: string, lastName: string) => {
        props.onSubmitMemberName(firstName, lastName);
    }

    const handleIdentifierUpdate = (identifier: string) => {
        props.onSubmitJerseyNumber(identifier);
    }

    const handleMetaDataUpdate = (metaData: MemberMetaData) => {
        props.onSubmitMetaData(metaData);
    }


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChangeTabs} aria-label="simple tabs example">
                        <Tab label="Info" {...a11yProps(0)} />
                        <Tab label="Card Data" {...a11yProps(1)} />
                        <Tab label="Action Image" {...a11yProps(2)} />
                        <Tab label="Documents" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    {props.member && <MemberInfoTab member={props.member} onUpdateMemberName={handleMemberNameUpdate} onUpdateJerseyNumber={handleIdentifierUpdate} onUpdate={props.onUpdate} />}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {props.member && <MemberCardDataTab member={props.member} onSubmit={handleMetaDataUpdate} isLoading={props.isLoadingCardData} />}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    {props.member && <MemberActionImageTab member={props.member}
                        onSubmit={props.onSubmitChangeAction}
                        isLoading={props.isLoadingAction}
                        actionImage={props.actionImage} />}
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    {props.member && <MemberDocumentTab member={props.member} />}
                </TabPanel>
            </Grid>
        </Grid>
    )
};
