import React, { useEffect, useState } from "react";
import { Header } from '../../shared/header';
import { Grid, Button, CircularProgress, MenuItem, TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import { generateAllAmericanForm, generateGpaForm } from '../../api/scholasticsApi';
import { CourseData, ReportCardGpaCommand } from '../../api/models/reportCard';
import { downloadBase64AsFile } from '../../utils/binaryHelper';
import { courseTypeOptions, getCourseTemplate, pointScaleTypeOptions } from "../../services/reportCardTemplateGenerator";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { UploadFileButton } from "../../shared/uploadFileButton";

export const ReportCardGpaPage = () => {
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [reportCardSource, setReportCardSource] = useState<string>('');
    const [gradeLevel, setGradeLevel] = useState<string>('');
    const [teamName, setTeamName] = useState<string>('');
    const [assocName, setAssocName] = useState<string>('Lutz Chiefs');
    const [leagueName, setLeagueName] = useState<string>('FYD Tampa');
    const [includeGrades, setIncludeGrades] = useState<boolean>(true);
    const [courses, setCourses] = React.useState<CourseData[] | undefined>(undefined);

    const [importFile, setImportFile] = useState<string | undefined>(undefined);

    useEffect(() => {

    }, [courses]);

    const generateGPA = async () => {

        const gpaFormRequest: ReportCardGpaCommand = {
            firstName: firstName,
            lastName: lastName,
            reportCardSource: reportCardSource,
            gradeLevel: gradeLevel,
            teamName: teamName,
            assocName: assocName,
            leagueName: leagueName,
            includeGrades: includeGrades,
            courses: courses
        };
        setIsLoadingInfo(true);
        const gpaForm = await generateGpaForm(gpaFormRequest);
        setIsLoadingInfo(false);
        downloadBase64AsFile(gpaForm.data, `${gpaForm.name}.xlsx`);
        resetPage();
    }

    function captureAllAmericanDocument(fileData: string, fileName: string, mimeType: string, size: number) {
        setImportFile(fileData);
    }

    const uploadAllAmericanData = async () => {

        if (importFile) {
            const importData = { csv: importFile };

            setIsLoadingInfo(true);
            await generateAllAmericanForm(importData);
            setIsLoadingInfo(false);
        }
    }

    const resetPage = () => {
        setFirstName('');
        setLastName('');
        setReportCardSource('');
        setGradeLevel('');
        setTeamName('');
        setAssocName('Lutz Chiefs');
        setLeagueName('FYD Tampa');
        setIncludeGrades(true);
        setCourses(undefined);
    }

    const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstName(e.target.value);
    };
    const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLastName(e.target.value);
    };

    const onChangeTeamName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTeamName(e.target.value);
    };

    const onChangeAssocName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAssocName(e.target.value);
    };

    const onChangeReportCardSource = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setReportCardSource(e.target.value);
        getRCTemplate(e.target.value, Number(gradeLevel));
    };

    const onChangeGradeLevel = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setGradeLevel(e.target.value);
        setReportCardSource('');
        setCourses(undefined);
    };

    const onChangeCourseName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {
        e.preventDefault();
        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.courseName = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangePointScaleType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {

        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.pointScaleType = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangePointScale = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {

        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.pointScale = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangeCourseType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {

        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.courseType = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangeTermGrade1 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {

        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.grades[0] = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangeTermGrade2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {

        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.grades[1] = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangeTermGrade3 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {

        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.grades[2] = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangeTermGrade4 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, courseId: number) => {
        if (courses) {
            let newList = courses.slice();
            let thisCourse = newList.find(a => a.id === courseId);
            if (thisCourse) {
                thisCourse.grades[3] = e.target.value;
                setCourses(newList);
            }
        }
    }

    const onChangeIncludeGrades = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeGrades(e.target.checked);
    };

    const getRCTemplate = (source: string, grade: number) => {
        let courseTemplate = getCourseTemplate(source, grade);
        setCourses(courseTemplate);
    }

    const getMainContent = () => {
        return (
            <div style={{ padding: 10 }}>
                <Grid item container direction='column' spacing={2}>
                    <Grid item container spacing={2}>
                        <Grid item xs={2}>

                            <TextField id={"txtFName"}
                                variant="outlined"
                                label="First Name"
                                sx={{ width: 150 }}
                                inputProps={{ maxLength: 40 }}
                                value={firstName}
                                size="small"
                                onChange={e => onChangeFirstName(e)} />
                        </Grid>

                        <Grid item xs={2}>

                            <TextField id={"txtLName"}
                                variant="outlined"
                                label="Last Name"
                                sx={{ width: 150 }}
                                inputProps={{ maxLength: 40 }}
                                value={lastName}
                                size="small"
                                onChange={e => onChangeLastName(e)} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField id={"txtTeam"}
                                variant="outlined"
                                sx={{ width: 150 }}
                                label="Team Name"
                                inputProps={{ maxLength: 50 }}
                                value={teamName}
                                size="small"
                                onChange={e => onChangeTeamName(e)} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField id={"txtAssoc"}
                                variant="outlined"
                                label="Assoc Name"
                                inputProps={{ maxLength: 50 }}
                                sx={{ width: 150 }}
                                value={assocName}
                                size="small"
                                onChange={e => onChangeAssocName(e)} />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField select
                                label="Select..."
                                size="small"
                                sx={{ width: 150 }}
                                value={gradeLevel}
                                onChange={onChangeGradeLevel}
                                helperText="Grade of report card">
                                <MenuItem key={0} value={0}>K</MenuItem>
                                <MenuItem key={1} value={1}>1</MenuItem>
                                <MenuItem key={2} value={2}>2</MenuItem>
                                <MenuItem key={3} value={3}>3</MenuItem>
                                <MenuItem key={4} value={4}>4</MenuItem>
                                <MenuItem key={5} value={5}>5</MenuItem>
                                <MenuItem key={6} value={6}>6</MenuItem>
                                <MenuItem key={7} value={7}>7</MenuItem>
                                <MenuItem key={8} value={8}>8</MenuItem>
                                <MenuItem key={9} value={9}>9</MenuItem>
                            </TextField>
                        </Grid>


                    </Grid>

                    <Grid item container spacing={2} >

                        <Grid item xs={3}>
                            <Box><FormControlLabel
                                label="Include Course Data"
                                control={
                                    <Checkbox
                                        id='showOuterBorder'
                                        checked={includeGrades}
                                        onChange={onChangeIncludeGrades}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } />

                            </Box>
                        </Grid>

                        <Grid item xs={3}>
                            {includeGrades && <TextField select
                                size="small"
                                sx={{ width: 250 }}
                                label="Select..."
                                value={reportCardSource}
                                onChange={onChangeReportCardSource}
                                helperText="Select a report card source">
                                <MenuItem key={1} value={'HillsboroughCounty'}>Hillsborough County</MenuItem>
                                <MenuItem key={2} value={'PascoCounty'}>Pasco County</MenuItem>
                                <MenuItem key={3} value={'ABCDF Scale'}>ABCDF Scale</MenuItem>
                                <MenuItem key={3} value={'ESNU Scale'}>ESNU Scale</MenuItem>
                            </TextField>}
                        </Grid>
                    </Grid>

                    {includeGrades && courses && <Grid item>
                        {renderRows()}
                    </Grid>}

                    <Grid item>

                        <Button disabled={gradeLevel === '' || !firstName || !lastName || !teamName || !assocName}
                            onClick={e => generateGPA()}
                            variant="contained"
                            color="primary">
                            Generate GPA Form
                        </Button>



                    </Grid>
                    <Grid item>
                        <UploadFileButton id="uploadDocument" onChange={captureAllAmericanDocument} />

                        <Button
                            onClick={e => uploadAllAmericanData()}
                            variant="contained"
                            color="primary">
                            Upload
                        </Button>

                    </Grid>

                </Grid>

            </div>
        );
    }

    const renderRows = () => {
        return courses && courses.map(course =>
            <TableRow key={"course-" + course.id}>

                <TableCell>
                    <TextField id={"txtCourse" + course.id}
                        size="small"
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                        value={course.courseName}
                        onChange={e => onChangeCourseName(e, course.id)} />
                </TableCell>

                <TableCell>
                    {course.pointScale}
                </TableCell>

                <TableCell>
                    <TextField select
                        size="small"
                        sx={{ width: 125 }}
                        value={course.pointScaleType}
                        onChange={e => onChangePointScaleType(e, course.id)}>
                        {pointScaleTypeOptions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                    </TextField>
                </TableCell>

                <TableCell>
                    <TextField select
                        size="small"
                        sx={{ width: 150 }}
                        value={course.courseType}
                        onChange={e => onChangeCourseType(e, course.id)}>
                        {courseTypeOptions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                    </TextField>
                </TableCell>

                <TableCell>
                    <TextField select
                        size="small"
                        sx={{ width: 60 }}
                        value={course.grades[0]}
                        onChange={e => onChangeTermGrade1(e, course.id)}>
                        {course.gradeOptions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                    </TextField>
                </TableCell>

                <TableCell>
                    <TextField select
                        size="small"
                        sx={{ width: 60 }}
                        value={course.grades[1]}
                        onChange={e => onChangeTermGrade2(e, course.id)}>
                        {course.gradeOptions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                    </TextField>
                </TableCell>

                <TableCell>
                    <TextField select
                        size="small"
                        sx={{ width: 60 }}
                        value={course.grades[2]}
                        onChange={e => onChangeTermGrade3(e, course.id)}>
                        {course.gradeOptions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                    </TextField>
                </TableCell>

                <TableCell>
                    <TextField select
                        size="small"
                        sx={{ width: 60 }}
                        value={course.grades[3]}
                        onChange={e => onChangeTermGrade4(e, course.id)}>
                        {course.gradeOptions.map(option => (<MenuItem key={option} value={option}>{option}</MenuItem>))}
                    </TextField>
                </TableCell>

            </TableRow>
        );
    }

    return (<Grid container direction='column'>

        <Grid item>
            <Header />
        </Grid>

        <Grid item container>
            <Grid item xs={false} sm={2} />
            <Grid item xs={12} sm={8} >

                <Grid item container direction='column'>
                    {!isLoadingInfo && getMainContent()}
                    {isLoadingInfo && <CircularProgress />}
                </Grid>
            </Grid>

            <Grid item xs={false} sm={2} />
        </Grid>

    </Grid>
    );

}