import axios from 'axios';
import { FileDataAsString, ImportCommand } from './models/common';
import { ReportCardGpaCommand } from './models/reportCard';

export async function  generateGpaForm(cardRequest: ReportCardGpaCommand): Promise<FileDataAsString>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = "/api/v1/report-card/gpa-sheet";
  const response = await axios.post(baseUrl + endpoint, cardRequest, { timeout: 60000 });
  return response.data as FileDataAsString;
}

export async function  generateAllAmericanForm (importData: ImportCommand): Promise<FileDataAsString>{
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = "/api/v1/report-card/all-american";
  const response = await axios.post(baseUrl + endpoint, importData, { timeout: 60000 });
  return response.data as FileDataAsString;
}

